import { Timestamp } from "interfaces";
import { BasicProfile } from "./user";

export interface Session {
  id: string;
  lastMsg?: Message;
  type: SessionType;
  updateTime: Timestamp;
  users: BasicProfile[];
}

export enum SessionType {
  P2P = 1,
  Group = 2,
  SuperGroup = 3,
}

export interface Message {
  id: string;
  type: MessageType;
  fromUser: BasicProfile;
  clientType: any;
  time: Timestamp;
  body: MessageBody;
  ext: string;
}

export type MessageBody = TextMessage &
  VoiceMessage &
  PhotoMessage &
  VideoMessage;

export enum MessageType {
  Text,
  Photo,
  Voice,
  Video,
  Geo,
  GroupNotification,
  File = 10,
  Custom = 100,
}

export interface TextMessage {
  msg: string;
}

export interface VoiceMessage {
  md5: string;
  ext: string; // "acc"
  dur: number;
  size: number;
  url: string;
}

export interface PhotoMessage {
  md5: string;
  name: string;
  ext: string; // "jpg"
  w: number; // width
  h: number; // height
  size: number;
  url: string;
}

export interface VideoMessage {
  md5: string;
  dur: number;
  ext: string; // "MOV"
  w: number;
  h: number;
  size: number;
  url: string;
}

export interface LocationMessage {
  lat: string;
  lng: string;
  title: string;
}
