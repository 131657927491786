import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { HOVER_COLOR_BG_DARK, HOVER_COLOR_BG_LIGHT } from "constant";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { statusState } from "states";
import { getTitleFromPathname } from "utils";

type SidebarNavLinkProps = {
  label?: string;
};

export function SidebarNavLink(props: SidebarNavLinkProps & LinkProps) {
  const resetStatus = useResetRecoilState(statusState);

  // 提取to和children属性
  const { to, children, ...restProps } = props;

  // 判断path是否匹配
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  // active & hover状态背景色
  const hoverBg = useColorModeValue(HOVER_COLOR_BG_LIGHT, HOVER_COLOR_BG_DARK);

  const handleClick = () => {
    resetStatus();
  };

  return (
    <Link
      to={to}
      {...restProps}
      onClick={handleClick}>
      <Box
        p={2}
        bg={match ? hoverBg : ""}
        borderRadius={8}
        _hover={{ bg: hoverBg }}>
        {children ? (
          children
        ) : (
          <Text>{props.label || getTitleFromPathname(to as string)}</Text>
        )}
      </Box>
    </Link>
  );
}
