import { Spinner } from "@chakra-ui/react";
import { InReviewProfileType } from "enums";
import { Suspense } from "react";
import { inReviewProfilesQuery } from "states";
import { ProfileList } from "./components/profile";

export function BannedProfilesPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <ProfileList state={inReviewProfilesQuery(InReviewProfileType.Banned)} />
    </Suspense>
  );
}
