import { InReviewProfileType } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { inReviewProfilesQuery, inReviewProfilesRequestIdState } from "states";

import { NextSubmitButton } from "./next-submit-button";

type SubmitProfilesButtonProps = {
  profileType: InReviewProfileType;
};

export function SubmitProfilesButton({
  profileType,
}: SubmitProfilesButtonProps) {
  return (
    <Suspense>
      <SubmitButtonContent profileType={profileType} />
    </Suspense>
  );
}

function SubmitButtonContent({ profileType }: SubmitProfilesButtonProps) {
  const profiles = useRecoilValue(inReviewProfilesQuery(profileType));

  return (
    <NextSubmitButton
      profiles={profiles}
      requestIdState={inReviewProfilesRequestIdState}
      isRestricted={profileType === InReviewProfileType.Restricted}
    />
  );
}
