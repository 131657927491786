import { Heading } from "@chakra-ui/react";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { useTitle } from "react-use";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { getTitleFromPathname } from "utils";

type MainHeaderProps = {
  totalState?: RecoilValueReadOnly<number>;
};

// 总数为-1时，显示...表示loading, 示例：Title (...)
export function MainHeader({ totalState }: MainHeaderProps) {
  const location = useLocation();

  const title = getTitleFromPathname(location.pathname);
  useTitle(title + " - " + process.env.REACT_APP_NAME);
  return (
    <Heading>
      {title}{" "}
      {totalState && (
        <Suspense fallback={<>(...)</>}>
          <TotalHeader totalState={totalState} />
        </Suspense>
      )}
    </Heading>
  );
}

function TotalHeader({
  totalState,
}: {
  totalState: RecoilValueReadOnly<number>;
}) {
  const total = useRecoilValue(totalState);

  return <>({total})</>;
}
