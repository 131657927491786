import { fetchInReviewProfiles } from "apis";
import dayjs from "dayjs";
import { getStatuses, InReviewProfileType } from "enums/module/status";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { errorState } from "states/common";
import { searchPageSizeState } from "states/search";
import { getErrorMessage } from "utils";
import { genders, genderState } from "views/components";

export const inReviewProfilesRequestIdState = atom({
  key: "profiles/inReview/requestId",
  default: dayjs().valueOf(),
});

const inReviewProfilesRespQuery = selectorFamily({
  key: "profiles/inReview/resp",
  get:
    (profileType: InReviewProfileType) =>
    async ({ get }) => {
      get(inReviewProfilesRequestIdState);

      const pageSize = get(searchPageSizeState);
      const genderTabIndex = get(genderState);

      const { riskStatus, regionStatus } = getStatuses(profileType);
      try {
        const resp = await fetchInReviewProfiles({
          page: 1,
          pageSize: pageSize,
          gender: genders[genderTabIndex],
          riskStatus,
          regionStatus,
        });

        return resp.data;
      } catch (error) {
        setRecoil(errorState, getErrorMessage(error));
      }
    },
});

export const inReviewProfilesQuery = selectorFamily({
  key: "profiles/inReview",
  get:
    (profileType: InReviewProfileType) =>
    ({ get }) => {
      return get(inReviewProfilesRespQuery(profileType))?.data ?? [];
    },
});

export const inReviewProfilesCountQuery = selectorFamily({
  key: "profiles/inReview/count",
  get:
    (profileType: InReviewProfileType) =>
    ({ get }) => {
      return get(inReviewProfilesRespQuery(profileType))?.page?.total ?? 0;
    },
});
