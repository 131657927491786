import {
  Gender,
  ProfileStatus,
  RegionStatus,
  RelationshipTag,
  RiskStatus,
} from "enums";
import {
  APIResponse,
  DiscoverProfile,
  ListParams,
  PageParams,
  Profile,
  ProfileDetail,
  ProfileMoreInfo,
  ProfileToUpdate,
  RelationshipCount,
  ReportedRecord,
  User,
  UserToSubmit,
} from "interfaces";
import { axiosInstance } from "../common/axios";
import { ApiURL } from "../common/urls";

// 待审核的profile
interface InReviewProfilesParams extends PageParams {
  gender: Gender;
  riskStatus?: RiskStatus;
  regionStatus?: RegionStatus[];
}

// 用户列表查询参数
export interface ProfilesParams extends ListParams {
  id?: string; // 用户id
  email?: string;
  imei?: string; // 设备唯一id
  relationshipsTag?: RelationshipTag[];
  status?: ProfileStatus[];
  riskStatus?: RiskStatus[];
}

// fetchInReviewProfiles
export function fetchInReviewProfiles(params: InReviewProfilesParams) {
  return axiosInstance.post<APIResponse<Profile[]>>(
    ApiURL.InReviewProfilesSearch,
    params
  );
}

// fetchIncompleteProfiles 获取未完成注册的profiles
export function fetchIncompleteProfiles(params: ListParams) {
  return axiosInstance.post<APIResponse<Profile[]>>(
    ApiURL.UnfinishedProfilesSearch,
    params
  );
}

// fetchProfiles
export function fetchProfiles(params: ProfilesParams) {
  return axiosInstance.post<APIResponse<User[]>>(ApiURL.ProfilesSearch, params);
}

// 根据id获取用户详情
export function fetchProfile(uid: string) {
  return axiosInstance.get<APIResponse<ProfileDetail>>(
    ApiURL.UserSearch + "/" + uid
  );
}

// 根据id获取用户详情的更多信息
export function fetchProfileMoreInfo(uid: string) {
  return axiosInstance.get<APIResponse<ProfileMoreInfo>>(
    ApiURL.UserMoreSearch + "/" + uid
  );
}

/**
 * 获取制定用户的好友、喜欢、评论、被喜欢的个数
 * @param uid 用户id
 * @returns {Promise<APIResponse<RelationshipCount>>} good friend count, like count, comment count, being liked count
 */
export function fetchRelationshipCount(uid: string) {
  return axiosInstance.post<APIResponse<RelationshipCount>>(
    ApiURL.UserRelationshipCountSearch,
    { uid }
  );
}

//根据id获取用户被举报记录
interface UserReportsParams extends PageParams {
  reportedUid?: string;
  reportUid?: string;
}

// 获取制定用户的被举报记录
export function fetchUserReports(params: UserReportsParams) {
  return axiosInstance.post<APIResponse<ReportedRecord[]>>(
    ApiURL.UserReportsSearch,
    params
  );
}

// 提交审核profile
export function submitUser(body: UserToSubmit) {
  return axiosInstance.post(ApiURL.UserReview, body);
}

// editProfile 编辑用户profile
export function editProfile(body: ProfileToUpdate) {
  return axiosInstance.post(ApiURL.EditProfile, body);
}

// deleteProfile 删除制定profile
export function deleteProfile(uid: string) {
  return axiosInstance.post(ApiURL.DeleteProfile, { uid });
}

interface DiscoverProfilesParams extends PageParams {
  uid: string;
}

// fetchDiscoverProfiles 获取指定用户id的discover数据
export function fetchDiscoverProfiles(params: DiscoverProfilesParams) {
  return axiosInstance.post<APIResponse<DiscoverProfile[]>>(
    ApiURL.UserDiscoverSearch,
    params
  );
}
