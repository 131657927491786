export enum RouterURI {
  /* Public */
  Login = "/login",
  Home = "/",

  /* Private */
  Dashboard = "/dashboard",

  // Profiles
  NormalProfiles = "/normal_profiles",
  ConfirmedProfiles = "/confirmed_profiles",
  SuspiciousProfiles = "/suspicious_profiles",
  BannedProfiles = "/banned_profiles",
  ReportedProfiles = "/reported_profiles",
  RestrictedRegionProfiles = "/restricted_region_profiles",

  // Content management
  AboutMe = "/about_me",
  SeekingDescriptions = "/seeking_descriptions",
  Photos = "/photos",

  // Analytics
  Devices = "/devices",
  Profiles = "/profiles",
  Seekings = "/seekings",
  UnfinishedProfiles = "/unfinished_profiles",

  // Relationships
  Passed = "/passed",
  Likes = "/likes",
  Matches = "/matches",
  Gifts = "/gifts",
  InstantChats = "/instant_chats",
  Blocks = "/blocks",

  // Payment management
  Subscriptions = "/subscriptions",
  InAppProducts = "/in-app_products",
  Orders = "/orders",
  Flows = "/flows",
  Transfers = "/transfers",
  Notifications = "/notifications",

  // Manage management
  GenderRequests = "/gender_requests",
  AppSecrets = "/app_secrets",
  Captchas = "/captchas",
  PhoneModels = "/phone_models",

  // Settings
  Members = "/members",
}
