import {
  AspectRatio,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { CARD_WIDTH } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { discoverProfilesCountQuery, discoverProfilesQuery } from "states";
import { timestampMsToAge } from "utils";
import { Pagination, UserIdContainer } from "views/components";

export function DiscoverView({ uid }: { uid: string }) {
  return (
    <Stack>
      <Suspense fallback={<Spinner />}>
        <ProfileWrap uid={uid} />
      </Suspense>
      <Pagination totalState={discoverProfilesCountQuery(uid)} />
    </Stack>
  );
}
export function ProfileWrap({ uid }: { uid: string }) {
  const profiles = useRecoilValue(discoverProfilesQuery(uid));

  return (
    <Wrap>
      {profiles.map((profile) => (
        <WrapItem key={profile.id}>
          <UserIdContainer
            uid={profile.id}
            isExternal>
            <Stack
              spacing="10px"
              w={CARD_WIDTH}>
              <AspectRatio ratio={10 / 16}>
                <Image
                  src={profile.avatar?.url}
                  objectFit="cover"
                />
              </AspectRatio>
              <Stack spacing="5px">
                <Heading size="xs">
                  {[
                    profile.name,
                    profile.birthday && timestampMsToAge(profile.birthday),
                  ].join(", ")}
                </Heading>
                <Text
                  fontSize={12}
                  opacity={0.5}>
                  {`${profile.distance} km`}
                </Text>
                <Text
                  fontSize={12}
                  opacity={0.5}>
                  {[
                    profile.currInfo?.geo?.city,
                    profile.currInfo?.geo?.country,
                  ].join(", ")}
                </Text>
              </Stack>
            </Stack>
          </UserIdContainer>
        </WrapItem>
      ))}
    </Wrap>
  );
}
