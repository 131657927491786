import {
  AspectRatio,
  Heading,
  HStack,
  Image,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CARD_WIDTH } from "constant";
import { BodyType, Ethnicity, Gender, Kink, RelationshipStatus } from "enums";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  editProfileQuery,
  profileQuery,
  rejectedAboutMeIdsState,
  rejectedSeekingIdsState,
} from "states";
import {
  inchesToHeightInFeetAndInches,
  timestampMsToAge,
  timestampMsToDateStr,
} from "utils";
import {
  DeviceView,
  GeoView,
  IpLink,
  ProfileMediaView,
  ProfileReportsView,
  UserIdContainer,
  VerifyImageView,
} from "views/components";
import { CustomSimpleGrid } from "./CustomSimpleGrid";
import { Caption } from "./details-caption";
import { Label } from "./details-label";
import { ProfilePaymentView } from "./ProfilePaymentView";
import { ProfileTextType, ProfileTextView } from "./ProfileTextView";

export function ProfileView({ uid }: { uid: string }) {
  return (
    <Stack spacing={4}>
      <Suspense fallback={<Spinner />}>
        <ProfileContentView uid={uid} />
      </Suspense>
      <Suspense fallback={<Spinner />}>
        <ProfileInfoView uid={uid} />
      </Suspense>
      <Suspense fallback={<Spinner />}>
        <ProfileDetailView uid={uid} />
      </Suspense>
      <Suspense fallback={<Spinner />}>
        <ProfilePaymentView uid={uid} />
      </Suspense>
      <Suspense fallback={<Spinner />}>
        <ProfileReportsView uid={uid} />
      </Suspense>
    </Stack>
  );
}

function ProfileContentView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));

  if (!profile) return <></>;

  return (
    <Stack spacing={4}>
      {/* images */}
      <HStack
        alignItems="flex-start"
        spacing={8}>
        {/* Media */}
        <Stack>
          <Caption title="Verify" />
          <VerifyImageView verifyImage={profile.verify} />
        </Stack>

        <Stack>
          <Caption title="Media" />

          {/* profile images */}
          <HStack
            spacing={0.5}
            alignItems="flex-end">
            {profile.photos &&
              profile.photos.map((media) => (
                <ProfileMediaView
                  w={CARD_WIDTH}
                  key={media.id}
                  media={media}
                />
              ))}
          </HStack>
        </Stack>

        <Spacer />
        {/* Partner */}
        <Stack>
          <Caption title="Partner" />
          {profile.partner && (
            <UserIdContainer
              uid={profile.partner.id}
              isExternal>
              <Stack
                spacing="10px"
                w={CARD_WIDTH}>
                <AspectRatio ratio={10 / 16}>
                  <Image
                    src={profile.partner.avatar?.url}
                    objectFit="cover"
                  />
                </AspectRatio>
                <Stack spacing="5px">
                  <Heading size="xs">{profile.partner.name}</Heading>
                  <Text opacity={0.5}>
                    {[
                      profile.partner.birthday &&
                        timestampMsToAge(profile.partner.birthday),
                      profile.partner.gender && Gender[profile.partner.gender],
                    ].join(" ")}
                  </Text>
                </Stack>
              </Stack>
            </UserIdContainer>
          )}
        </Stack>
      </HStack>

      <Stack>
        <Caption title="Text" />

        <HStack alignItems="flex-start">
          {profile.aboutMe && (
            <ProfileTextView
              profileText={profile.aboutMe}
              type={ProfileTextType.AboutMe}
              rejectedIdState={rejectedAboutMeIdsState}
            />
          )}

          {profile.seekingDescription && (
            <ProfileTextView
              profileText={profile.seekingDescription}
              type={ProfileTextType.Seeking}
              rejectedIdState={rejectedSeekingIdsState}
            />
          )}
        </HStack>
      </Stack>
    </Stack>
  );
}

function ProfileInfoView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));
  const editedProfile = useRecoilValue(editProfileQuery(uid));

  if (!profile) return <></>;

  const geo = (editedProfile ?? profile).currInfo?.geo;

  return (
    <Stack spacing={4}>
      <Caption title="Info" />
      {/* Register Info */}
      <CustomSimpleGrid>
        <Label title="Register Device">
          <DeviceView device={profile.registerInfo?.device} />
        </Label>
        <Label
          title="Register IP"
          value={profile.registerInfo?.ip ? profile.registerInfo?.ip : "--"}>
          <IpLink
            ip={profile.registerInfo?.ip}
            showFull
          />
        </Label>
        <Label title="Register Address">
          <GeoView geo={profile.registerInfo?.geo} />
        </Label>
      </CustomSimpleGrid>

      {/* Current Info */}
      <CustomSimpleGrid>
        <Label title="Current Device">
          <DeviceView device={profile.currInfo?.device} />
        </Label>
        <Label
          title="Current IP"
          value={profile.currInfo?.ip ? profile.currInfo.ip : "--"}>
          <IpLink
            ip={profile.currInfo?.ip}
            showFull
          />
        </Label>
        <Label title="Current Address">
          <GeoView geo={geo} />
        </Label>
      </CustomSimpleGrid>
    </Stack>
  );
}

function ProfileDetailView({ uid }: { uid: string }) {
  const profile = useRecoilValue(profileQuery(uid));

  if (!profile) return <></>;

  return (
    <Stack>
      {/* Details */}

      <Caption title="Details" />
      <CustomSimpleGrid>
        <Label
          title="Height"
          value={inchesToHeightInFeetAndInches(profile.height)}
        />
        <Label
          title="Ethnicity"
          value={profile.ethnicity ? Ethnicity[profile.ethnicity] : "--"}
        />
        <Label
          title="Body Type"
          value={profile.bodyType ? BodyType[profile.bodyType] : "--"}
        />
        <Label
          title="Relationship Status"
          value={
            profile.relationshipStatus
              ? RelationshipStatus[profile.relationshipStatus]
              : "--"
          }
        />
        <Label
          title="Kinks"
          value={
            profile.kinks.length > 0
              ? profile.kinks.map((k) => Kink[k]).join(", ")
              : "--"
          }
        />
      </CustomSimpleGrid>

      {/* Date At */}
      <CustomSimpleGrid>
        <Label
          title="Created At"
          value={timestampMsToDateStr(profile.createdAt)}
        />
        <Label
          title="Updated At"
          value={timestampMsToDateStr(profile.updatedAt)}
        />
        <Label
          title="Boost Expiry"
          value={
            profile.boostExpiry
              ? timestampMsToDateStr(profile.boostExpiry)
              : "--"
          }
        />
        <Label
          title="Activated At"
          value={
            profile.activatedAt
              ? timestampMsToDateStr(profile.activatedAt)
              : "--"
          }
        />
      </CustomSimpleGrid>
    </Stack>
  );
}
