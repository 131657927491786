import { Flex, Spacer, Stack, useColorModeValue } from "@chakra-ui/react";
import { DEFAULT_COLOR_BG_DARK, DEFAULT_COLOR_BG_LIGHT } from "constant";
import { RouterURI } from "router/router.uri";
import { Logo } from "../other";
import { SidebarCapital } from "./sidebar-capital";
import { SidebarNavLink } from "./sidebar-nav-link";

export function Sidebar() {
  const bg = useColorModeValue(DEFAULT_COLOR_BG_LIGHT, DEFAULT_COLOR_BG_DARK);

  return (
    <Flex
      as="aside"
      h="100vh"
      direction="column"
      bg={bg}>
      <Logo />

      <Stack
        flex="1"
        flexDirection="column"
        overflow="auto"
        p={4}>
        {sidebarConfig.map((item, index) => (
          <SidebarItemComponent
            key={index}
            item={item}
          />
        ))}

        <Spacer />
      </Stack>
    </Flex>
  );
}

const SidebarItemComponent: React.FC<{ item: SidebarItem }> = ({ item }) => {
  if (item.children) {
    return (
      <>
        <SidebarCapital title={item.label} />
        {item.children.map(
          (child, index) =>
            child.link && (
              <SidebarNavLink
                key={index}
                to={child.link}
                label={child.label}
              />
            )
        )}
      </>
    );
  }

  return (
    <>
      {item.link && (
        <SidebarNavLink
          to={item.link}
          label={item.label}
        />
      )}
    </>
  );
};

interface SidebarItem {
  label?: string;
  link?: RouterURI;
  children?: SidebarItem[];
}

const sidebarConfig: SidebarItem[] = [
  {
    link: RouterURI.Dashboard,
  },
  {
    label: "In Review",
    children: [
      { link: RouterURI.NormalProfiles },
      { link: RouterURI.ConfirmedProfiles },
      { link: RouterURI.SuspiciousProfiles },
      { link: RouterURI.BannedProfiles },
      { link: RouterURI.RestrictedRegionProfiles },
      { link: RouterURI.ReportedProfiles },
    ],
  },
  {
    label: "Content",
    children: [
      { link: RouterURI.AboutMe },
      { link: RouterURI.SeekingDescriptions },
      { link: RouterURI.Photos },
    ],
  },
  {
    label: "Analytics",
    children: [
      { link: RouterURI.Devices },
      { link: RouterURI.Profiles },
      { link: RouterURI.Seekings },
      { link: RouterURI.UnfinishedProfiles },
    ],
  },
  {
    label: "Relationships",
    children: [
      { link: RouterURI.Passed },
      { link: RouterURI.Likes },
      { link: RouterURI.Matches },
      { link: RouterURI.Gifts },
      { link: RouterURI.InstantChats },
      { link: RouterURI.Blocks },
    ],
  },
  {
    label: "Payment",
    children: [
      { link: RouterURI.Subscriptions },
      { link: RouterURI.InAppProducts },
      { link: RouterURI.Orders },
      { link: RouterURI.Flows },
      { link: RouterURI.Transfers },
      { link: RouterURI.Notifications },
    ],
  },
  {
    label: "App Manage",
    children: [
      { link: RouterURI.GenderRequests },
      { link: RouterURI.PhoneModels },
      { link: RouterURI.AppSecrets },
      { link: RouterURI.Captchas },
    ],
  },
  {
    label: "Settings",
    children: [{ link: RouterURI.Members }],
  },
];
