import { Button } from "@chakra-ui/react";
import { submitUser } from "apis";
import dayjs from "dayjs";
import { AutoStatus, Status } from "enums";
import { Media, Profile, ProfileText, UserToSubmit } from "interfaces";
import { useState } from "react";
import { RecoilState, useRecoilState, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  errorState,
  rejectedAboutMeIdsState,
  rejectedMediaIdsState,
  rejectedSeekingIdsState,
  rejectedUserIdsState,
} from "states";
import { getErrorMessage } from "utils";

type NextSubmitButtonProps = {
  requestIdState: RecoilState<number>;
  isRestricted?: boolean;
  profiles?: Profile[];
  photos?: Media[];
  bios?: ProfileText[];
  seekings?: ProfileText[];
};

export function NextSubmitButton(props: NextSubmitButtonProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateRequestId = useSetRecoilState(props.requestIdState);

  const [rejectedUserIds, setRejectedUserIds] =
    useRecoilState(rejectedUserIdsState);
  const [rejectedMediaIds, setRejectedMediaIds] = useRecoilState(
    rejectedMediaIdsState
  );
  const [rejectedAboutMeIds, setRejectedAboutMeIds] = useRecoilState(
    rejectedAboutMeIdsState
  );
  const [rejectedSeekingIds, setRejectedSeekingIds] = useRecoilState(
    rejectedSeekingIdsState
  );

  const getUserToSubmit = () => {
    const { isRestricted, profiles, photos, bios, seekings } = props;

    const userToSubmit: UserToSubmit = {
      user: { adoptIds: [], refuseIds: [] },
      regionRisk: { adoptIds: [], refuseIds: [] },
      media: { adoptIds: [], refuseIds: [], reviewIds: [] },
      bio: { adoptIds: [], refuseIds: [] },
      seeking: { adoptIds: [], refuseIds: [] },
    };

    if (profiles) {
      profiles.forEach((profile) => {
        if (isRestricted) {
          if (rejectedUserIds.includes(profile.id)) {
            userToSubmit.regionRisk?.refuseIds?.push(profile.id);
          } else {
            userToSubmit.regionRisk?.adoptIds?.push(profile.id);
          }
        } else if (rejectedUserIds.includes(profile.id)) {
          userToSubmit.user?.refuseIds?.push(profile.id);
        }

        if (profile.verify) {
          if (rejectedMediaIds.includes(profile.verify.id)) {
            userToSubmit.media?.refuseIds?.push(profile.verify.id);
          } else if (profile.verify.status === Status.Pending) {
            userToSubmit.media?.adoptIds?.push(profile.verify.id);
          }
        }

        profile.photos.forEach((media) => {
          if (rejectedMediaIds.includes(media.id)) {
            userToSubmit.media?.refuseIds?.push(media.id);
          } else if (media.status === Status.Pending) {
            userToSubmit.media?.adoptIds?.push(media.id);
          }

          if (media.autoStatus === AutoStatus.Pending) {
            userToSubmit.media?.reviewIds?.push(media.id);
          }
        });
      });
    }

    if (photos) {
      photos.forEach((media) => {
        if (rejectedMediaIds.includes(media.id)) {
          userToSubmit.media?.refuseIds?.push(media.id);
        } else if (media.status === Status.Pending) {
          userToSubmit.media?.adoptIds?.push(media.id);
        }

        if (media.autoStatus === AutoStatus.Pending) {
          userToSubmit.media?.reviewIds?.push(media.id);
        }
      });
    }

    if (bios) {
      bios.forEach((text) => {
        if (rejectedAboutMeIds.includes(text.id)) {
          userToSubmit.bio?.refuseIds?.push(text.id);
        } else if (text.status === Status.Pending) {
          userToSubmit.bio?.adoptIds?.push(text.id);
        }
      });
    }

    if (seekings) {
      seekings.forEach((text) => {
        if (rejectedSeekingIds.includes(text.id)) {
          userToSubmit.seeking?.refuseIds?.push(text.id);
        } else if (text.status === Status.Pending) {
          userToSubmit.seeking?.adoptIds?.push(text.id);
        }
      });
    }

    return userToSubmit;
  };

  const onSubmit = async () => {
    // 提交按钮转菊花
    setIsSubmitting(true);

    try {
      console.log(getUserToSubmit());
      await submitUser(getUserToSubmit());
      // 刷个新
      refresh();
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }

    setIsSubmitting(false);
  };

  const refresh = () => {
    updateRequestId(dayjs().valueOf());

    setRejectedAboutMeIds([]);
    setRejectedMediaIds([]);
    setRejectedSeekingIds([]);
    setRejectedUserIds([]);
  };

  return (
    <Button
      colorScheme="green"
      onClick={onSubmit}
      isLoading={isSubmitting}>
      Submit
    </Button>
  );
}
